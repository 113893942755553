import dayjs from 'dayjs';
import type { Deal, Deals, DealsSections } from '../types';
import { getDealSectionType } from './getDealItemType';
import { readSocketRatesData } from '~/src/features/terminal/infrastructure/store';

const getSectionKey = (item: Deal) => {
	const type = getDealSectionType(item);
	const expirationTimestamp = new Date(item.expirationDate).getTime();
	const expirationMinute = Math.ceil(expirationTimestamp / 60000);

	return `${item.asset}__${type}__${item.percent}__${expirationMinute}`;
};

const getSectionSum = (items: Deals): number => {
	return items.reduce((acc, item) => acc + item.sum, 0);
};

const getMapItems = (items: Deals): Record<string, Deals> => {
	const map: Record<string, Deals> = {};

	items.forEach((item) => {
		const key = getSectionKey(item);

		if (key in map) {
			map[key].push(item);
		}
		else {
			map[key] = [item];
		}
	});

	return map;
};

export const getDealIncome = (item: Deal) => {
	const socketRatesData = readSocketRatesData();
	const asset = item.asset;

	const normalizedData = Object.entries(socketRatesData).map(([key, value]) => ({
		originalKey: key,
		normalizedKey: key.replace(/^.*:/, '').replace(/[-/]/g, ''),
		value,
	}));

	const normalizedAsset = asset.replace(/[-/]/g, '');

	const matchedData = normalizedData.find(item => item.normalizedKey === normalizedAsset);

	if (!matchedData) return 0;
	const startSum = item.startSum;
	if (item.expirationAssetPrice) {
		return item.income;
	}

	if (item.type === 'up' && startSum < matchedData.value.close) {
		return item.income;
	}
	else if (item.type === 'down' && startSum > matchedData.value.close) {
		return item.income;
	}

	return 0;
};

export const getDealsSections = (items: Deals): DealsSections => {
	const map = getMapItems(items);

	const sections: DealsSections = Object.values(map).map((items) => {
		const asset = items[0].asset;

		const income = (() => {
			let sum = 0;
			items.forEach((item: Deal) => {
				sum += getDealIncome(item);
			});

			return sum;
		})();
		return {
			sum: getSectionSum(items),
			income,
			asset,
			items,
			percent: items[0].percent,
			stringifiedTime: dayjs(items[0].startDate).utc().format('HH:mm'),
			type: getDealSectionType(items[0]),
		};
	});

	return sections;
};

export const getClosedDealsSections = (items: Deals): DealsSections => {
	const map = getMapItems(items);

	const sections: DealsSections = Object.values(map).map((items) => {
		const asset = items[0].asset;

		const income = (() => {
			let sum = 0;
			items.forEach((item: Deal) => {
				sum += item.income;
			});

			return sum;
		})();
		return {
			sum: getSectionSum(items),
			income,
			asset,
			items,
			percent: items[0].percent,
			stringifiedTime: dayjs(items[0].startDate).utc().format('HH:mm'),
			type: getDealSectionType(items[0]),
		};
	});

	return sections;
};
